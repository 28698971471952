










import {Component, Vue} from 'vue-property-decorator';
import PayoutPolicyTemplate from '@/components/PayoutPolicyTemplate.vue';
import Modal from '@/components/Modal.vue';

@Component({
  components: {Modal, PayoutPolicyTemplate},
})
export default class PayoutPolicyModal extends Vue {
  showPopup = false;
}
