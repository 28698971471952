
import {Component, Mixins, Prop} from 'vue-property-decorator';
import VueCharts from 'vue-chartjs-typescript';
import {ChartDataInterface} from '@/types/chartDataInterface';

@Component({})
export default class LineChart extends Mixins(VueCharts.Line) {
  @Prop({type: Object, default: null}) readonly chartData!: ChartDataInterface;
  mounted() {
    this.renderChart(this.chartData, {
      responsive: true,
      maintainAspectRatio: false,
      legend: false,
      CategoryScale: true,
      elements: {
        line: {
          backgroundColor: ['red', 'green'],
          borderColor: '#f8462a',
          borderWidth: 3,
          fill: false,
          tension: 0,
        },
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              color: 'rgba(0, 0, 0, 0)',
              drawBorder: false,
              drawTicks: false,
              zeroLineWidth: 0,
            },
            ticks: {
              fontColor: 'rgba(0, 0, 0, 0.35)',
              fontSize: 16,
              fontFamily: 'TestSöhne-Leicht',
              lineHeight: 'normal',
              padding: 48,
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              color: 'rgba(0, 0, 0, 0.35)',
              drawBorder: false,
              drawTicks: false,
              zeroLineWidth: 0,
            },
            ticks: {
              fontColor: 'rgba(0, 0, 0, 0.35)',
              fontSize: 16,
              fontFamily: 'TestSöhne-Leicht',
              lineHeight: 'normal',
              padding: 42,
            },
          },
        ],
      },
    });
  }
}
