



































import {Component, Watch} from 'vue-property-decorator';
import DropDown from '@/components/DropDown.vue';
import {vxm} from '@/store';
import moment from 'moment';
import {mixins} from 'vue-class-component';
import NumbersMixin from '@/mixins/NumbersMixin';
import Paginate from 'vuejs-paginate';
import DatePickerModal from '@/components/modals/DatePickerModal.vue';
import MomentDateMixin from '@/mixins/MomentDateMixin';
import {PayoutHistoryInterface} from '@/types/PayoutHistoryInterface';

@Component({
  components: {DatePickerModal, DropDown, Paginate},
})
export default class PayoutHistory extends mixins(NumbersMixin, MomentDateMixin) {
  dateFilters = {
    year: 'Last Year',
    month: 'Last Month',
    week: 'Last Week',
    allTime: 'All time',
  };
  filters = {
    date: 'allTime',
  };
  data: PayoutHistoryInterface[] = [];
  statisticDates: {start: string; end: string} = {
    start: '',
    end: '',
  };
  showRequestSuccess = false;

  /* pagination params */
  totalPages = 1;
  params: any = {
    page: 1,
    perPage: 10,
    startDate: '',
    endDate: '',
    performerId: '',
  };

  @Watch('params.page') onPageChange() {
    this.loadHistory();
  }

  created() {
    vxm.user.getMe().then((res) => {
      this.params.performerId = res.data._id;
      this.loadHistory();
    });
  }

  loadHistory() {
    vxm.model
      .getPayoutHistory(
        `page=${this.params.page}&perPage=${this.params.perPage}&startDate=${this.statisticDates.start}&endDate=${this.statisticDates.end}&performerId=${this.params.performerId}`,
      )
      .then((res) => {
        this.totalPages = Math.ceil(res.data.totalCount / this.params.perPage);
        this.data = res.data.message;
      })
      .catch((error) => {
        return error;
      });
  }

  changeDates(key: string) {
    this.filters.date = key;
    this.params.page = 1;
    if (key === 'allTime') {
      this.statisticDates = {end: '', start: ''};
    } else {
      this.statisticDates.end =
        moment()
          .utc(false)
          .subtract(1, key as any)
          .endOf(key as any)
          .format('YYYY-MM-DD') + 'T09:00:00.000Z';
      this.statisticDates.start =
        moment()
          .utc(false)
          .subtract(1, key as any)
          .startOf(key as any)
          .format('YYYY-MM-DD') + 'T09:00:00.000Z';
    }
    this.loadHistory();
  }

  statisticDatesChanged(dates) {
    this.statisticDates = dates;
    this.statisticDates.start += 'T09:00:00.000Z';
    this.statisticDates.end += 'T09:00:00.000Z';
    this.filters.date = 'custom';
    this.params.page = 1;
    this.loadHistory();
  }
}
