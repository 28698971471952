



















































































































import {Component, Mixins, Watch} from 'vue-property-decorator';
import DropDown from '@/components/DropDown.vue';
import LineChart from '@/components/LineChart.vue';
import ProgressBar from '@/components/ProgressBar.vue';
import TopVideosModal from '@/components/modals/TopVideosModal.vue';
import {vxm} from '@/store';
import {ChartDataInterface} from '@/types/chartDataInterface';
import NumbersMixin from '@/mixins/NumbersMixin';
import DatePickerModal from '@/components/modals/DatePickerModal.vue';
import {VideoInterface} from '@/types/videoInterface';
import moment from 'moment';
import FinanceStatistics from '@/views/model-views/FinanceStatistics.vue';
import PayoutHistory from '@/views/model-views/PayoutHistory.vue';
import TransactionsTable from '@/views/model-views/TransactionsTable.vue';
import Leaderboard from '@/views/model-views/Leaderboard.vue';
import {ModelDataInterface} from '@/types/userDataInterface';
import Loader from '@/components/Loader.vue';
import PayoutPolicyModal from '@/components/modals/PayoutPolicyModal.vue';
import ViewModelAgreementModal from '@/components/modals/ViewModelAgreementModal.vue';
import ContactUsModalNew from '@/components/modals/ContactUsModalNew.vue';
import CookieLaw from 'vue-cookie-law';

@Component({
  components: {
    PayoutPolicyModal,
    Loader,
    Leaderboard,
    FinanceStatistics,
    PayoutHistory,
    TransactionsTable,
    TopVideosModal,
    ProgressBar,
    LineChart,
    DropDown,
    DatePickerModal,
    ContactUsModalNew,
    ViewModelAgreementModal,
    CookieLaw,
  },
})
export default class Dashboard extends Mixins(NumbersMixin) {
  isMobile = false;
  statisticsKey = 0;
  subscriptionsKey = 0;
  statisticType = 'profile-view';
  statisticDates: {start: string; end: string} = {
    start: moment()
      .subtract(1, 'month')
      .format('YYYY-MM-DD'),
    end: moment().format('YYYY-MM-DD'),
  };
  subscriptionsDates: {start: string; end: string} = this.statisticDates;
  subscriptions: ChartDataInterface = {labels: [], datasets: []};
  statistics: ChartDataInterface = {labels: [], datasets: []};
  topVideos: Array<VideoInterface> = [];
  model: ModelDataInterface | null = null;
  statisticLoading = false;
  subscriptionsLoading = false;
  showContactUs = false;

  @Watch('statisticType')
  onStatTypeChanged() {
    this.getStatistic();
  }

  get showViews(): boolean {
    return vxm.user.totalVideo > 5;
  }

  get subscriptionsCount(): number {
    return this.model ? this.model.totalSubscriber : 0;
  }

  get likesCount(): number {
    return this.model ? this.model.totalLike : 0;
  }

  created() {
    window.addEventListener('resize', this.onResize);
    this.onResize();
  }

  onResize() {
    this.isMobile = window.innerWidth <= 767;
  }
  setCookieFalse(props): void {
    localStorage.setItem('cookie:accepted', 'false');
    props.close();
  }
  mounted() {
    this.getStatistic();
    this.getSubscriptions();
    vxm.model
      .getTopVideos({page: 1, take: 5, performerId: vxm.user.data._id})
      .then((res) => {
        this.topVideos = res.items;
      })
      .catch((error) => {
        return error;
      });
    vxm.fan
      .getPerformer(vxm.user.data._id)
      .then((res) => {
        this.model = res.data;
      })
      .catch((error) => {
        return error;
      });
  }

  getStatistic() {
    this.statisticLoading = true;
    vxm.model
      .getStatistic({
        type: this.statisticType,
        startDate: this.statisticDates.start,
        endDate: this.statisticDates.end,
      })
      .then(() => {
        this.statisticLoading = false;
        this.statisticsKey++;
        this.statistics = JSON.parse(JSON.stringify(vxm.model.statistic[this.statisticType]));
      })
      .catch((error) => {
        return error;
      });
  }

  getSubscriptions() {
    this.subscriptionsLoading = true;
    vxm.model
      .getStatistic({
        type: 'subscribers',
        startDate: this.subscriptionsDates.start,
        endDate: this.subscriptionsDates.end,
      })
      .then(() => {
        this.subscriptionsLoading = false;
        this.subscriptionsKey++;
        this.subscriptions = JSON.parse(JSON.stringify(vxm.model.statistic.subscribers));
      })
      .catch((error) => {
        return error;
      });
  }

  statisticDatesChanged(dates) {
    this.statisticDates = dates;
    this.getStatistic();
  }

  subscriptionsDatesChanged(dates) {
    this.subscriptionsDates = dates;
    this.getSubscriptions();
  }

  likesInPercents(likes: number): number {
    return (likes / vxm.user.data.totalLike) * 100;
  }

  logout() {
    this.$store.dispatch('resetStore').then(() => {
      localStorage.removeItem('model');
      localStorage.removeItem('fan');
      this.$socket.disconnect();
      this.$router.push('/login');
    });
  }
}
