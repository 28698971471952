
















import {Component, Mixins} from 'vue-property-decorator';
import Modal from '../Modal.vue';
import NumbersMixin from '@/mixins/NumbersMixin';
import DropDown from '@/components/DropDown.vue';
import {VideoInterface} from '@/types/videoInterface';
import {vxm} from '@/store';

@Component({
  components: {DropDown, Modal},
})
export default class TopVideosModal extends Mixins(NumbersMixin) {
  showModal = false;
  topVideos: Array<VideoInterface> = [];

  mounted() {
    vxm.model
      .getTopVideos({page: 1, performerId: vxm.user.data.id})
      .then((res) => {
        this.topVideos = res.items;
      })
      .catch((error) => {
        return error;
      });
  }

  close() {
    this.showModal = false;
  }
}
